import React, { FC } from "react";
import { graphql, PageProps } from "gatsby";
import { MainLayout } from "@/layouts/MainLayout";
import Helmet from "react-helmet";
import { SEO } from "@/components/basic/SEO";
import { emailRegExp } from "@/utils/validation";
import { Input } from "@/components/basic/Input";
import { ValidatedInput } from "@/components/basic/ValidatedInput";
import { PreviousPageInput } from "@/components/basic/PreviousPageInput";
import { Textarea } from "@/components/basic/Textarea";
import { SuccessPageContent } from "@/components/SuccessPageContent";
import { FailurePageContent } from "@/components/FailurePageContent";
import { FormSubmitButton } from "@/components/basic/FormSubmitButton";
import { SiteFooterNewsletterButton } from "@/components/basic/SiteFooterNewsletterButton";
import { SiteFooterWrapper } from "@/components/basic/SiteFooterWrapper";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormHandler } from "@/hooks/useFormHandler";
import {
  invalidFormatMessage as invalid,
  requiredFieldMessage as required,
} from "@/utils/validationMessages";
import { MailingListTooltip } from "@/components/MailingListTooltip";

const JobsContactForm: FC<PageProps<GatsbyTypes.Query>> = ({ data }) => {
  const {
    formMetaTitle,
    formMetaDescription,
    formMetaKeywords,
  } = data.contentfulCareerPage;
  const {
    state,
    errors,
    isValid,
    isRecaptcha,
    register,
    handleCaptchaChange,
    handleFormSubmit,
    handleMailingListChange,
  } = useFormHandler(process.env.FORMCARRY_REQUEST_CAREER_CONTACT_US_ID);
  // todo: Current Carry Form plan doesnt support file upload
  // const [fileName, setFileName] = useState('');
  // const handleSelectedFile = (event) => {
  //   setFileName(event.target.files[0].name);
  // };

  return (
    <MainLayout>
      <Helmet>
        <body className="ltr career-contact-form" id="intro" />
      </Helmet>
      <SEO
        title={formMetaTitle}
        description={formMetaDescription.formMetaDescription}
        keywords={formMetaKeywords}
      />
      {state.submitted && (
        <SuccessPageContent
          heading="Thank you!"
          text="Your request has been submitted. Thank you for contacting us!"
        />
      )}
      {state.error && (
        <FailurePageContent
          goBackUrl="/jobs-contact-form"
          goBackText="return"
        />
      )}
      {!state.submitted && !state.error && (
        <section className="overlay overlay-static">
          <div className="l-wrapper l-with-dbl-vertical-gutters-mobile l-dbl-push-top">
            <div className="l-10-cols-tablet l-8-cols-desktop l-island default-form color-bg-negative l-with-dbl-gutters-mobile">
              <div className="l-full">
                <h2 className="color-primary">Work with us!</h2>
                <p className="l-push-bottom">
                  Whether you have questions about recruiting processes or open
                  job positions, our team is here to support you every step of
                  the way. Write to us :)!
                </p>
                <p className="l-push-bottom">
                  <strong className="color-error">*</strong>
                  required fields
                </p>
              </div>
              <div className="l-push-bottom l-bleed">
                <form onSubmit={handleFormSubmit} encType="multipart/form-data">
                  <div className="l-push-bottom clearfix">
                    <div className="l-half-tablet">
                      <ValidatedInput
                        name="name"
                        label="Name"
                        register={register}
                        errorMessage={errors.name && required}
                      />
                    </div>
                    <div className="l-half-tablet">
                      <ValidatedInput
                        name="surname"
                        label="Surname"
                        register={register}
                        errorMessage={errors.surname && required}
                      />
                    </div>
                  </div>
                  <div className="l-push-bottom clearfix">
                    <div className="l-half-tablet">
                      <ValidatedInput
                        name="email"
                        type="email"
                        label="Your e-mail address"
                        register={register}
                        pattern={emailRegExp}
                        errorMessage={
                          errors.email &&
                          (errors.email.type === "pattern" ? invalid : required)
                        }
                      />
                    </div>
                    <div className="l-half-tablet">
                      <Input
                        name="phone"
                        label="Your phone number"
                        type="tel"
                      />
                    </div>
                  </div>
                  <div className="l-full-tablet  l-push-bottom">
                    <ValidatedInput
                      name="topic"
                      label="The topic of your message"
                      register={register}
                      errorMessage={errors.topic && required}
                    />
                  </div>
                  <div className="l-full-mobile l-push-bottom">
                    <Textarea
                      label="Message text"
                      name="message"
                      register={register}
                      errorMessage={errors.message && required}
                    />
                  </div>
                  {/* todo: Current Carry Form plan doesnt support file upload*/}
                  {/*<div className="l-full-mobile l-push-bottom">*/}
                  {/*  <Input*/}
                  {/*    type="file"*/}
                  {/*    accept="application/pdf"*/}
                  {/*    name="upload"*/}
                  {/*    label={`Upload CV`}*/}
                  {/*    handleChange={handleSelectedFile}*/}
                  {/*  />*/}
                  {/*  <div>{fileName}</div>*/}
                  {/*</div>*/}
                  <div className="l-full-mobile l-dbl-push-bottom text-12">
                    I hereby consent to my personal data included in this
                    contact form being processed for recruitment purposes by
                    ANIXE Polska sp. z o.o. having its registered seat in
                    Wrocław, at ul. Grabiszyńska 251A, 53-234 Wrocław. Detailed
                    information on the scope and manner of data processing can
                    be found in the{" "}
                    <a className="text-underline" href="/privacy">
                      Data Protection Declaration.
                    </a>
                  </div>
                  <div className="l-full-mobile l-dbl-push-bottom text-12 d-flex-box ">
                    <Input
                      type="checkbox"
                      name="mailingList"
                      handleChange={handleMailingListChange}
                      value="on"
                    />
                    <label htmlFor="mailingList">
                      Yes, I want to receive from ANIXE Polska Sp. z o.o.
                      information and offers to the above e-mail address (e.g.
                      ANIXE’s Newsletter). Add me to your mailing list.
                      <MailingListTooltip />
                    </label>
                  </div>
                  <PreviousPageInput />
                  <div className="align-center clearfix">
                    <div className="l-half-tablet">
                      <ReCAPTCHA
                        sitekey={process.env.FORMCARRY_SITEKEY}
                        onChange={handleCaptchaChange}
                      />
                    </div>
                    <FormSubmitButton
                      text="agree & send"
                      disabled={!isRecaptcha || !isValid}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
      <SiteFooterWrapper>
        <SiteFooterNewsletterButton />
      </SiteFooterWrapper>
    </MainLayout>
  );
};

export default JobsContactForm;
export const query = graphql`
  {
    contentfulCareerPage {
      formMetaTitle
      formMetaDescription {
        formMetaDescription
      }
      formMetaKeywords
    }
  }
`;
